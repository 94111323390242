/**获取年龄 */
function getAge(msg) {
    var birthday = new Date(msg.replace(/-/g, "/"));
    var d = new Date();
    var age =
        d.getFullYear() -
        birthday.getFullYear() -
        (d.getMonth() < birthday.getMonth() ||
            (d.getMonth() == birthday.getMonth() &&
                d.getDate() < birthday.getDate())
            ? 1
            : 0);
    return age;
}
/**
 * 
 * @param {生日} birthday 
 * @param {年龄} age （兼容老数据）
 * @returns 
 */
function getage(birthday="",age="") {
    if(!birthday&&!age){
        return "保密";
    }
    if (birthday) {
        return getAge(birthday)+"岁";
    } else if (age) {
        return age+'岁';
    } else {
        return "保密";
    }
}

export default getage;
