function sex(msg) {
    if ((msg + '').indexOf('男') != -1) {
        return "先生";
    } else {
        if (msg) {
            return "女士";
        } else {
            return "先生";
        }
    }
}
export default sex;
