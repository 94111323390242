import base from "./base";
import sex from "./sex"
function resumelocal(allmsges,route){
    //性别
    let sharesex = sex(allmsges.sex);
    let allmsg = allmsges;
    let $route = route;
    let obj = JSON.stringify({
        sharetitle: "精选人才推荐",
        sharedesc:
          (allmsg.name + "").substr(0, 1) +
          sharesex +
          " " +
          allmsg.jop +
          "\n" +
          allmsg.city +
          " " +
          (allmsg.years_working ? allmsg.years_working : 0) +
          "年\n" +
          allmsg.industry +
          " " +
          allmsg.salary,
        isshare: "1",
        sub_code: localStorage.getItem("phone"),
        id: $route.query.id,
        share_phone: localStorage.getItem("phone"),
        favoid: $route.query.id, //简历id
        is_download: $route.query.is_download,
        mylinkurl:sharesex=="先生"?base.seximg1:base.seximg2
      });
      localStorage.setItem("shareobj", obj);
}

  export default resumelocal;