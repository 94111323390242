<!--
 * @Author: your name
 * @Date: 2021-04-19 18:30:28
 * @LastEditTime: 2021-06-01 20:46:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\sub\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation msg="简历详情">
      <!-- <span @click="showshezhi = true" class="sz">设置</span> -->
      <van-icon
        v-if="isshoucang"
        @click="shoucangmeth"
        color="#ff4800"
        size=".4rem"
        name="star"
      />
      <van-icon @click="shoucangmeth" v-else size=".4rem" name="star-o" />
    </navigation>
    <div class="tuijclass">
      推荐公司：{{
        allmsg.company && allmsg.company.length > 5 && isshare
          ? allmsg.company.substr(0, 3) +
            new Array(allmsg.company.length - 4).join("*") +
            allmsg.company.substr(-3, 2)
          : allmsg.company
      }}
    </div>
    <div class="mmc">
      <div class="classmsg">
        <div>
          <div class="pms">
            <div>{{ allmsg.name }}</div>
            <img
              class="pms_2"
              v-if="(allmsg.sex + '').indexOf('男') != -1"
              src="../../assets/sex_1.png"
              alt=""
            />
            <img
              class="pms_2"
              v-if="(allmsg.sex + '').indexOf('女') != -1"
              src="../../assets/sex_2.png"
              alt=""
            />
            <div class="pms_1" v-if="allmsg.politics">
              {{ allmsg.politics }}
            </div>
            <!-- <img
              class="pms_2"
              v-if="(allmsg.sex + '').indexOf('男') != -1"
              src="../../assets/sex_1.png"
              alt=""
            />
            <img
              class="pms_2"
              v-if="(allmsg.sex + '').indexOf('女') != -1"
              src="../../assets/sex_2.png"
              alt=""
            /> -->
          </div>
          <div v-if="allmsg.u_time" class="gxtime">
            {{ allmsg.u_time ? "更新时间:" + allmsg.u_time.split(" ")[0] : "" }}
          </div>
          <div class="phmsg">
            <div>
              {{
                allmsg.job_status == 1
                  ? "离职 随时到岗"
                  : allmsg.job_status == 2
                  ? "在职-月内到岗"
                  : allmsg.job_status == 3
                  ? "在职-考虑机会"
                  : allmsg.job_status == 4
                  ? "在职，暂不考虑"
                  : "保密"
              }}
            </div>
            <div>
              手机:
              {{
                is_download == 10
                  ? allmsg.mobile
                  : (allmsg.mobile + "").substr(0, 3) +
                    "****" +
                    (allmsg.mobile + "").substr(7)
              }}
            </div>
          </div>
          <div class="imgcc">
            <div>
              <img src="../../assets/mm1.png" alt="" />{{
                allmsg.years_working
              }}年
            </div>
            <div>
              <img src="../../assets/mm2.png" alt="" />{{
                allmsg.education_background
              }}
            </div>
            <div><img src="../../assets/mm3.png" alt="" />{{ age }}</div>
          </div>
        </div>
        <div class="userclass">
          <img
            class="imgsex"
            v-if="(allmsg.sex + '').indexOf('男') != -1"
            src="../../assets/user_1.png"
            alt=""
          />
          <img
            class="imgsex"
            v-if="(allmsg.sex + '').indexOf('女') != -1"
            src="../../assets/user_2.png"
            alt=""
          />
          <img class="pms_3" src="../../assets/shiming.png" alt="" />
        </div>
      </div>
      <div class="henggang"></div>

      <div class="allgw">
        <div class="gw1">
          <div></div>
          <div>求职期望</div>
        </div>
        <div id="gw_2" class="gw2">
          <div class="gw2_1">{{ allmsg.jop }}，{{ allmsg.province }}</div>
        </div>
        <div class="moclaall">
          <div></div>
          <div class="mocla">{{ allmsg.salary }}</div>
        </div>
        <div class="gw3">期望行业：{{ allmsg.industry }}</div>
      </div>
      <div class="henggang"></div>
      <div v-if="qiyemsg != ''" class="alljingcla">
        <div class="gw1 jingl">
          <div></div>
          <div>工作经历</div>
        </div>
        <div>
          <div
            v-for="(item, index) in qiyemsg"
            v-bind:key="index"
            class="jingcla"
          >
            <div>
              <div>
                {{
                  item.company_name && item.company_name.length > 5 && isshare
                    ? item.company_name.substr(0, 3) +
                      new Array(item.company_name.length - 4).join("*") +
                      item.company_name.substr(-3, 2)
                    : item.company_name
                }}
              </div>
            </div>
            <div class="jltwo">
              <div>
                {{
                  item.position
                    ? String(item.position).replace(/\[|]/g, "")
                    : ""
                }}
              </div>
              <div class="timeclass">
                {{ item.entry_time }}至{{ item.quit_time }}
              </div>
            </div>

            <mytextarea
              :index="index"
              stylemsg="border:none;font-size: 0.28rem;font-weight: 400;color: #606266;line-height: 0.48rem;"
              :readonlymsg="true"
              :msg="
                item.working_experience &&
                item.working_experience.replace(/\[]/g, '\n')
              "
            ></mytextarea>
          </div>
        </div>
      </div>

      <div class="henggang"></div>

      <!-- <div v-if="allmsg.province != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>户籍所在地</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.household_register }}</div>
          <div class="mocla"></div>
        </div>
      </div> -->
      <!-- <div v-if="allmsg.email != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>联系邮箱</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">
            {{ allmsg.email == "" ? "无" : "****************" }}
          </div>
          <div class="mocla"></div>
        </div>
      </div> -->
      <!-- <div v-if="allmsg.industry != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>意向行业</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.industry }}</div>
          <div class="mocla"></div>
        </div>
      </div> -->

      <div v-if="educational_experience != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>教育经历</div>
        </div>
        <div class="scc">
          <div
            v-for="(item, index) in educational_experience"
            v-bind:key="index"
            class="jingcla"
          >
            <div>
              <div>{{ item.school }}</div>
            </div>
            <div class="timebackclass">
              <div>{{ item.education_background }} {{ item.major }}</div>
              <div v-if="item.start_time && item.end_time" class="timeclass">
                {{ item.start_time }}至{{ item.end_time }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="henggang"></div>
      <!-- <div v-if="allmsg.politics != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>政治面貌</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.politics }}</div>
          <div class="mocla"></div>
        </div>
      </div> -->
      <!-- <div v-if="allmsg.years_working != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>工作年限</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.years_working }}</div>
          <div class="mocla"></div>
        </div>
      </div> -->
      <!-- <div v-if="allmsg.province != ''" class="allgw">
        <div class="gw1">
          <div></div>
          <div>求职区域</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">
            {{ allmsg.province }} {{ allmsg.city }} {{ allmsg.district }}
          </div>
          <div class="mocla"></div>
        </div>
      </div> -->

      <!-- <div v-if="allmsg.honor != ''" class="allgw">

        <div class="gw1">
          <div></div>
          <div>职业资格证书/荣誉奖项</div>
        </div>
        <div class="gw2">
          <div class="gw2_1">{{ allmsg.honor }}</div>
          <div class="mocla"></div>
        </div>
      </div> -->

      <div class="gw1 jingl">
        <div></div>
        <div>技能云</div>
      </div>
      <div class="jin">
        <div @click="jimeth(0)" :class="jiindex == 0 ? 'selcl' : 'selcl2'">
          工作技能
        </div>
        <div @click="jimeth(1)" :class="jiindex == 1 ? 'selcl' : 'selcl2'">
          生活技能
        </div>
        <div @click="jimeth(2)" :class="jiindex == 2 ? 'selcl' : 'selcl2'">
          综合技能
        </div>
      </div>
      <div class="duana">
        <div v-show="skillindex == 0">
          <div
            class="duana2"
            v-if="skillList.work && skillList.work.length > 0"
          >
            <div
              :class="item.status == 1 ? 'ddiv1' : 'ddiv2'"
              v-for="(item, index) in skillList.work"
              v-bind:key="index"
            >
              {{ item.skill_name }}
            </div>
          </div>
          <div v-if="skillList.work && skillList.work.length < 1">
            <nomsgvue></nomsgvue>
          </div>
        </div>
        <div v-show="skillindex == 1">
          <div
            class="duana2"
            v-if="skillList.lift && skillList.lift.length > 0"
          >
            <div
              :class="item.status == 1 ? 'ddiv1' : 'ddiv2'"
              v-for="(item, index) in skillList.lift"
              v-bind:key="index"
            >
              {{ item.skill_name }}
            </div>
          </div>
          <div v-if="skillList.lift && skillList.lift.length < 1">
            <nomsgvue></nomsgvue>
          </div>
        </div>
        <div v-show="skillindex == 2">
          <div
            class="duana2"
            v-if="skillList.other && skillList.other.length > 0"
          >
            <div
              :class="item.status == 1 ? 'ddiv1' : 'ddiv2'"
              v-for="(item, index) in skillList.other"
              v-bind:key="index"
            >
              {{ item.skill_name }}
            </div>
          </div>
          <div v-if="skillList.other && skillList.other.length < 1">
            <nomsgvue></nomsgvue>
          </div>
        </div>
      </div>
      <div
        v-if="allmsg"
        :class="isshare ? 'faqi1' : is_download == 10 ? 'faqi2' : 'faqi1'"
      >
        <button id="bu1e" @click="getmsgmeth">
          {{
            isshare
              ? "获取联系方式"
              : is_download == 10
              ? "已下载"
              : "获取联系方式"
          }}
        </button>
        <button :style="is_download != 10?'background: rgb(121, 121, 121);color:white;border:none;':''" @click="toresumeEvaluation" id="bu2e">能力分析</button>
        <button :style="is_download != 10?'background: rgb(121, 121, 121);color:white;border:none;':''" v-if="$route.query.sub_id" @click="toresumematth" id="bu3e">
          胜任力匹配
        </button>
      </div>
    </div>
    <div class="nomsgclass"></div>
    <van-action-sheet
      v-model="showshezhi"
      :actions="actions"
      cancel-text="取消"
      description="状态设置"
      close-on-click-action
      @select="selectshezhi"
    />
    <sharepage ref="sharitid"></sharepage>
    <share ref="shareid"></share>
    <van-action-sheet
      v-model="show_gangwei"
      :actions="dingyuelist"
      cancel-text="取消"
      description="请选择岗位"
      close-on-click-action
      @select="select_meth"
    />
    <!-- 悬浮窗 -->
    <!-- <div class="fx">
      <div @click="toresumeEvaluation">能力分析</div>
      <div @click="toresumematth">胜任力分析</div>
    </div> -->
  </div>
</template>
<script>
import sharepage from "../../views/sharepage/index";
import getage from "../../../util/age";
import share from "../../components/share/index";
import nomsgvue from "../../components/nomsg/index";
import islogin from "../../../util/islogin";
import sex from "../../../util/sex";
import eventBus from "../../../util/eventbus";

import resumelocal from "../../../util/resumelocal";
export default {
  components: {
    nomsgvue,
    sharepage,
    share,
  },
  data() {
    return {
      jiindex: "0",
      showshezhi: false,
      actions: [{ name: "入职" }],
      isshoucang: false, //是否收藏过
      allmsg: "", //数据
      skillList: "", //工作技能
      skillindex: 0, //工作技能下标
      qiyemsg: "",
      age: "", //年龄
      educational_experience: "",
      isshare: false, //是否是分享进入
      share_phone: "", //分享手机号
      sharesex: "", //分享性别
      is_download: "", //该简历是否下载
      show_gangwei: false,
      dingyuelist: [], //所有订阅列表
    };
  },
  created() {
    this.clickmeth();
    let sharemm = JSON.parse(sessionStorage.getItem("sharemsg"));
    if (sharemm != "" && sharemm != undefined && sharemm.urlsharees) {
      this.$route.query.id = sharemm.id;
      this.$route.query.is_download = 0;
      this.share_phone = sharemm.share_phone;
      this.isshare = true;
    } else {
    }
    this.$http
      .post("/firm/v1/login/details", {
        reqType: "resume",
        report_id: atob(this.$route.query.id),
      })
      .then((res) => {
        this.allmsg = JSON.parse(res.data).data.resumeDetail;
        console.log(this.allmsg);
        this.skillList = JSON.parse(res.data).data.skillList;
        if (this.allmsg.work_resume != "") {
          this.qiyemsg = JSON.parse(this.allmsg.work_resume);
        } else {
          this.qiyemsg = "";
        }
        //该简历是否下载
        if (this.allmsg.is_download) {
          this.is_download = this.allmsg.is_download;
        }
        //分享进入名字加密
        if (this.isshare) {
          let a = this.allmsg.name;
          this.allmsg.name = a.substr(0, 1) + new Array(a.length).join("*");
        }
        //教育经历
        if (this.allmsg.educational_experience != "") {
          this.educational_experience = JSON.parse(
            this.allmsg.educational_experience
          );
        } else {
          this.educational_experience = "";
        }
        //年龄
        this.age = getage(this.allmsg.birthday, this.allmsg.age);

        this.allmsg.is_collect == 1
          ? (this.isshoucang = true)
          : (this.isshoucang = false);

        this.clickmeth();
        this.$refs.sharitid.getmsg();
      });
  },
  methods: {
    toresumematth() {
      if(this.is_download!=10){
        this.$toast("请先购买简历");
        return;
      }
      this.$router.push({
        path: "resumematch",
        query: {
          id: this.$route.query.id,
          sub_id: btoa(this.$route.query.sub_id),
          is_download: this.is_download,
          evalmsg: JSON.stringify({
            id: btoa(this.allmsg.id),
            paytype: 10,
            is_perfect: this.allmsg.is_perfect,
            tipmsg: "获取联系方式",
            share_phone: this.share_phone,
            isshare: this.isshare, //是否是分享进入
          }),
        },
      });
    },
    toresumeEvaluation() {
      if(this.is_download!=10){
        this.$toast("请先购买简历");
        return;
      }
      this.$router.push({
        path: "resumeEvaluation",
        query: {
          id: this.$route.query.id,
          is_download: this.is_download,
          evalmsg: JSON.stringify({
            id: btoa(this.allmsg.id),
            paytype: 10,
            is_perfect: this.allmsg.is_perfect,
            tipmsg: "获取联系方式",
            share_phone: this.share_phone,
            isshare: this.isshare, //是否是分享进入
          }),
        },
      });
    },
    /**
     * 点击去邀请
     */
    clickmeth() {
      if (this.allmsg) {
        resumelocal(this.allmsg, this.$route);
      }
    },
    select_meth(e) {
      console.log(e);
      this.getmsgmeth2(e.id);
    },
    /**
     * 收藏简历
     */
    shoucangmeth() {
      if (this.isshoucang == false) {
        this.$http
          .post("/firm/v1/Resume/collect", {
            reqType: "resume",
            report_id: this.allmsg.id,
          })
          .then((res) => {
            this.isshoucang = !this.isshoucang;

            this.$toast(JSON.parse(res.data).msg);
          });
      } else {
        this.$http
          .post("/firm/v1/Resume/collectDel", {
            reqType: "resume",
            report_id: this.allmsg.id,
          })
          .then((res) => {
            this.isshoucang = !this.isshoucang;
            this.$toast(JSON.parse(res.data).msg);
          });
      }
    },
    getdingyuelist() {
      this.$http
        .post("/firm/v1/Resume/subscribeLists", {
          pageno: 1,
          reqType: "resume",
          limit: 15,
          type: 1, //1-开启 2-关闭 不传查询全部
        })
        .then((res) => {
          let listmsg = JSON.parse(res.data).data.subscribreLists;
          this.dingyuelist = [];
          listmsg.forEach((e) => {
            this.dingyuelist.push({ name: e.theme, id: e.id });
          });
        });
    },
    /**
     * 获取联系方式
     */
    getmsgmeth() {
      if (!this.isshare) {
        if (this.is_download == 10) {
          return;
        }
      }
      this.show_gangwei = true;
      this.getdingyuelist();
    },
    getmsgmeth2(subId) {
      //分享进入
      if (this.isshare) {
        if (islogin()) {
        } else {
          this.$router.push("/downloadresume");
          return;
        }
      }
      this.$router.push({
        path: "evalpay",
        query: {
          msg: JSON.stringify({
            id: btoa(this.allmsg.id),
            paytype: 10,
            is_perfect: this.allmsg.is_perfect,
            tipmsg: "获取联系方式",
            share_phone: this.share_phone,
            isshare: this.isshare, //是否是分享进入
            subId: subId, //岗位id
          }),
        },
      });
    },
    /**获取年龄 */
    selectshezhi(msg) {},
    jimeth(index) {
      this.skillindex = index;
      this.jiindex = index;
    },
  },
  destroyed() {
    sessionStorage.removeItem("sharemsg");
  },
};
</script>
<style scoped>
.dingyuelistclass {
  padding: 0.3rem;
  font-size: 0.4rem;
}
.dingyuelistclass > div {
  margin: 0.1rem 0;
  border-top-color: #cccbcb;
  border-top-style: solid;
  border-top-width: 1px;
  padding: 0.1rem;
}
.dingyuelistclass > :last-child {
  border-bottom-color: #cccbcb;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.fx {
  position: fixed;
  top: 50%;
  right: 0;
  font-size: 0.3rem;
  background-color: #ffffff;
  padding: 0.2rem 0.3rem;
  border: 1px solid #dbdcdf;
  border-right: none;
  border-radius: 0.1rem 0 0 0.1rem;
  box-shadow: 0 0 0.2rem #dbdcdf;
  color: rgb(57, 119, 246);
  font-weight: bold;
}
.moclaall {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.06rem;
}
.gxtime {
  color: #606266;
  font-size: 0.28rem;
  margin-top: 0.16rem;
  font-weight: 400;
}
.timebackclass {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.02rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
}
.jltwo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.1rem;
  margin-bottom: 0.26rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
}
.alljingcla {
  margin-top: 0.14rem;
}
.henggang {
  height: 0.14rem;
  background-color: #f6f6f7;
  position: absolute;
  width: 100%;
  left: 0;
}
.tuijclass {
  text-align: center;
  font-size: 0.28rem;
  line-height: 0.33rem;
  font-weight: 400;
  color: #ffffff;
  position: relative;
  background-color: #ff6d20;
  padding: 0.14rem;
}
#gw_2 {
  margin-bottom: 0.06rem;
}
.scc > :last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.gw2_1 {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.mocla {
  white-space: nowrap;
  font-size: 0.32rem;
  font-weight: 600;
  color: #ec5d33;
  line-height: 0.48rem;
}
.duana2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0.29rem;
}
.ddiv1 {
  padding: 0.2rem 0.5rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5f19;
  line-height: 0.4rem;
  background: #ffffff;
  border-radius: 0.4rem;
  border: 0.02rem solid #ff5f19;
  text-align: center;
  margin-bottom: 0.24rem;
  margin-right: 0.24rem;
}
.ddiv2 {
  padding: 0.2rem 0.5rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgb(51, 51, 51);
  line-height: 0.4rem;
  background: #ebebeb;
  border-radius: 0.4rem;
  border: 0.02rem solid #ebebeb;
  text-align: center;
  margin-bottom: 0.24rem;
  margin-right: 0.24rem;
}
.jin {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #9d9d9d;
  line-height: 0.42rem;
  margin-top: 0.46rem;
  margin-bottom: 0.42rem;
}
.jin > :nth-child(2) {
  margin: 0 0.64rem;
}
.selcl {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.45rem;
}
.selcl::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.08rem;
  background-color: red;
  margin-left: 50%;
  transform: translateX(-50%);
  background: linear-gradient(270deg, #fc9c45 0%, #fe4e04 100%);
  border-radius: 0.06rem;
  margin-top: 0.1rem;
}
.selcl2::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.08rem;
  background-color: red;
  margin-left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 0.06rem;
  margin-top: 0.1rem;
}
.nomsgclass {
  height: 2rem;
}
.faqi {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 0.28rem 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dfdfdf;
}
.faqi button {
  padding: 0.28rem 0;
  text-align: center;
  border: none;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  width: 90%;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  margin-left: 5%;
}
.faqi2 {
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 0.28rem 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dfdfdf;
}
.faqi2 button {
  padding: 0.28rem 0;
  text-align: center;
  border: none;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  width: 90%;
  background: rgb(121, 121, 121);
  border-radius: 0.16rem;
  margin-left: 5%;
}
.faqi1 {
  position: fixed;
  display: flex;
  bottom: 0;
  width: 100%;
  left: 0;
  background-color: #ffffff;
  padding: 0.28rem 0;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #dfdfdf;
}
.faqi1 button {
  padding: 0.28rem 0;
  text-align: center;
  border: none;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.42rem;
  width: 90%;
  background: linear-gradient(90deg, #ff4800 0%, #fc9e46 100%);
  border-radius: 0.16rem;
  margin-left: 5%;
}

.jingcla {
  padding-top: 0.34rem;
  padding-bottom: 0.26rem;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
}
.jingcla > :first-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.32rem;
  font-weight: 500;
  color: #606266;
  line-height: 0.48rem;
}

.jingl {
  padding-top: 0.36rem;
  margin-top: 0.14rem;
}
.allgw {
  padding: 0.4rem 0;
  margin-top: 0.14rem;
}
.gw2 {
  margin: 0.16rem 0;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;

  font-size: 0.32rem;

  font-weight: 500;
  color: #606266;
  line-height: 0.48rem;
}
.gw3 {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #909399;
  line-height: 0.48rem;
}
.gw1 {
  display: flex;
  align-items: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #303133;
  line-height: 0.48rem;
}
.gw1 > :first-child {
  width: 0.16rem;
  height: 0.16rem;
  border-radius: 50%;
  background-color: #ff4900;
  margin-right: 0.24rem;
}
.zhicla {
  padding: 0.35rem 0;
  padding: 0.33rem 0;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #dfdfdf;
}
.imgcc {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #313131;
  line-height: 0.48rem;
}
.imgcc > div {
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
}
.imgcc img {
  width: 0.3rem;
  margin-right: 0.17rem;
}

.zhicla > :first-child {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  line-height: 0.4rem;
}
.zhicla > :nth-child(2) {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313131;
  line-height: 0.48rem;
  display: flex;
  align-items: center;
  margin-top: 0.36rem;
}
.mmc {
  margin: 0 0.38rem;
}
.userclass {
  position: relative;
}
.userclass .imgsex {
  width: 1.27rem;
  height: 1.27rem;
  border-radius: 50%;
}
.pms_3 {
  position: absolute;
  width: 1.08rem;
  bottom: -0.1rem;
  left: 50%;
  transform: translateX(-50%);
}
.phmsg {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #606266;
  line-height: 0.33rem;
  margin-top: 0.16rem;
  margin-bottom: 0.42rem;
}
.phmsg > :nth-child(2) {
  margin: 0 0.08rem;
}
.pms {
  display: flex;
  align-items: center;
}
.pms > :first-child {
  font-size: 0.4rem;
  font-weight: 500;
  color: #303133;
  line-height: 0.48rem;
}
.pms_1 {
  padding: 0.04rem 0.2rem;
  background: #ff9858;
  margin: 0 0.18rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.33rem;
}
.pms_2 {
  width: 0.45rem;
  position: relative;
  margin-left: 0.1rem;
}

.classmsg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.46rem 0;
}
.sz {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 0.48rem;
}
.allmsgclass {
  background-color: #ffffff;
  min-height: 100%;
}

.faqi1 #bu1e,
.faqi2 #bu1e {
  min-width: 2.4rem;
}
.faqi1 #bu2e,
.faqi2 #bu2e {
  width: auto;
  white-space: nowrap;
  max-width: 1.8rem;
  min-width: 1.8rem;
  margin-right: 5%;
  border: 0.03rem solid #ff5f19;
  color: #ff5f19;
  background: #ffffff;
}
.faqi1 #bu3e,
.faqi2 #bu3e {
  width: auto;
  white-space: nowrap;
  max-width: 1.8rem;
  min-width: 1.8rem;
  margin-right: 5%;
  margin-left: 0;
  border: 0.03rem solid #ff5f19;
  color: #ff5f19;
  background: #ffffff;
}
</style>
